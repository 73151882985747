<template>
    <section class="m-active m-scroll" ref="scroll"  @scroll="handleScroll($event)" :style="{'background':data.theme_bgcolor}">
    <!-- 头部导航 -->
        <div class="header" >
            <div class="glass"></div>
            <p>
                <img @click="$router.back()" src="@/assets/goods/icon_back_2@2x.png" alt="back">
            </p>
            <span></span>
            <span></span>
        </div>
        <div class="header-bg">
            <img :src="data.rule_pic_url" alt="">
        </div>
        <div class="m-rule">
            <div>
                <van-field type="textarea" v-model="data.rule_content" readonly autosize />
            </div>
        </div>
    </section>
</template>
<script>
export default {
    data() {
        return {
            offsetTop:0,
            data:""
        }
    },
    created() {
        this.data = this.$route.query
    },
    mounted() {
        
    },
    methods: {
        // 内容滑动事件
        handleScroll(e){
            this.offsetTop = e.target.scrollTop
        },
    }
};
</script>
<style scoped lang="less">
@import './active.less';
.m-rule {
    width: 100%;
    padding: 0 12px;
    font-size: 16px;
    color: #333;
    &>div {
        padding: 10px;
        border-radius: 10px;
        background: #fff;
        /deep/.van-field__body {
            color: #333;
            font-size: 18px;
        }
    }
}
</style>